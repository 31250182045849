import React from 'react';
import './Banner.scss';
// import {Link} from 'react-router-dom';
import icon from '../../assets/banner/icons/Calling.png';
import bannerImg from '../../assets/banner/1.png';
// import doctorImg from '../../assets/banner/doctor.png';
import bannerPattern from '../../assets/banner/pattern_02.png';
import shapeOne from '../../assets/banner/vector_01.png';
import shapeTwo from '../../assets/banner/vector_02.png';
import shapeThree from '../../assets/banner/vector_03.png';
import shapeFour from '../../assets/banner/pattern.png';

const Banner = () => {

    return (
        <section className='section-bg section-common banner-section'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h1>We are your family dentist near you in North Adelaide!</h1>
                                    <p>Smile Bright Family Dental is a family orientated dental surgery providing a range of dental treatments from preventive dentistry to simple restorations, complex crown and bridge treatment and orthodontic services.</p>
                                    <p>Our dentists aim to provide treatment to the highest standards and are experienced in treating young patients and patients with dental anxiety.</p>

                                    <div className="banner-bottom">
                                        {/* <div className="theme-btn">
                                            <Link to="/contact">Book an appointment</Link>
                                        </div> */}

                                        <div className="banner-call">
                                            <div className='icon'>
                                                <img src={icon} alt="icon" />
                                            </div>
                                            <div className='call-text'>
                                                <p>Contact Us</p>
                                                <h6>08 8239 1140</h6>
                                                <h6>smilebrightfamilydentalclinic@gmail.com</h6>
                                                <h6>Level 1/274 Melbourne Street</h6>
                                                <h6>North Adelaide SA 5006</h6>
                                                <br></br>
                                                <h6>Tuesday—Friday</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="banner-img-area" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="banner-img">
                                        <img src={bannerImg} alt="banner model" />
                                    </div>
                                    {/* <div className='info-box'>
                                        <div className="info-img">
                                            <img src={doctorImg} alt="doctor" />
                                        </div>
                                        <div className='info-text'>
                                            <p>Dr. Samantha Alice</p>
                                            <p><small>Consultant</small></p>
                                        </div>
                                    </div>   */}

                                    {/* Vector Shapes */}
                                    <div className="shapes">
                                        <img src={shapeOne} alt="shape" />
                                        <img src={shapeTwo} alt="shape" />
                                        <img src={shapeThree} alt="shape" />
                                        <img src={shapeFour} alt="shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner Pattern Vector*/}
            <img className='banner-pattern' src={bannerPattern} alt="banner pattern" />
            <div class="anchor" id='about-us'></div>
        </section>
    );
};

export default Banner;