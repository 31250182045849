import React from 'react';
import './Emergency.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import EmergencyImg from '../../assets/emergency.png';
// import { Link } from 'react-router-dom';

const description = 
    <div>
        <p>Dr. Kimberley Goldfinch is a seasoned dentist with over 10 years of experience in South Australia. She holds a Bachelor of Oral Health in Dental Science and a Graduate Diploma of Dentistry from Griffith University in Queensland.</p>
        <p>Throughout her career, Dr. Goldfinch has worked extensively at the Adelaide Dental Hospital and the South Australian School Dental Service, providing general dentistry for all ages. She has developed a special interest in paediatric dentistry, focusing on providing a positive dental experience for children through early education and preventive care. To help manage patient anxiety, she provides relative analgesia, commonly known as "happy" or "laughing" gas.</p>
        <p>Her patient-centered approach has made her a trusted choice for families. Dr. Goldfinch is a member of the Australian Dental Association SA Branch and the Australian and New Zealand Society of Paediatric Dentists.</p>
        <p>In her personal time, she enjoys exploring South Australia with her young family. Dr. Goldfinch’s dedication to patient care and oral health reflects her commitment to improving dental health for all ages.</p>
    </div>

const Emergency = () => {
    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitle subTitle="Meet the Dentist" title="Dr. Kimberley Goldfinch" description={description}/>

                            {/* <div className="theme-btn">
                                <Link to='/'>Book an appointment</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div class="anchor" id='contact-us'></div>
        </section>
    );
};

export default Emergency;