import React from 'react';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';

const ContactForm = () => {
    return (
        // <form>
        //     <div className="row">
        //         <div className="col-lg-6">
        //             <div class="form-group">
        //                 <label>Name</label>
        //                 <input type="email" class="form-control" placeholder="Enter your name..." />
        //             </div>
        //         </div>
        //         <div className="col-lg-6">
        //             <div class="form-group">
        //                 <label>E-mail Address</label>
        //                 <input type="email" class="form-control" placeholder="Enter email address..." />
        //             </div>
        //         </div>
        //         <div className="col-lg-6">
        //             <div class="form-group">
        //                 <label>Service</label>
        //                 <select class="form-control">
        //                     <option>Teeth Whitening</option>
        //                     <option>Teeth Whitening</option>
        //                     <option>Teeth Whitening</option>
        //                     <option>Teeth Whitening</option>
        //                 </select>
        //             </div>
        //         </div>
        //         <div className="col-lg-6">
        //             <div class="form-group">
        //                 <label>Department</label>
        //                 <select class="form-control">
        //                     <option>Select Department</option>
        //                     <option>Select Department</option>
        //                     <option>Select Department</option>
        //                     <option>Select Department</option>
        //                 </select>
        //             </div>
        //         </div>
        //         <div className="col-lg-12">
        //             <div class="form-group">
        //                 <label for="exampleFormControlTextarea1">Messages</label>
        //                 <textarea class="form-control" placeholder='Enter your messages...' rows="3"></textarea>
        //             </div>
        //         </div>

        //         <div className="col-lg-6">
        //             <button type="submit" class="btn appointment-btn">Book an appointment</button>
        //         </div>
                // <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <img src={icon} alt="icon" />
                        </div>
                        <div className='call-text'>
                            <p>Contact Us</p>
                            <h6>08 8239 1140</h6>
                            <h6>smilebrightfamilydentalclinic@gmail.com</h6>
                            <h6>Level 1/274 Melbourne Street</h6>
                            <h6>North Adelaide SA 5006</h6>
                            <br></br>
                            <h6>Tuesday—Friday</h6>
                        </div>
                    </div>
                // </div>
            // </div>
        // </form>
    );
};

export default ContactForm;