import React from 'react';
import './Appointment.scss';
// import SectionTitle from '../../components/SectionTitle/SectionTitle';
import {AiFillHome} from "react-icons/ai";
import ContactForm from '../../components/ContactForm/ContactForm';

const Appointment = () => {

    return (
        <section className='appointment-section pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div class="anchor" id='contact-us'></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="google-map">
                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13087.502263818347!2d138.6023577!3d-34.9095721!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c9e4d0b29c6f%3A0x4ce34a6b40e9f9be!2sSmile%20Bright%20Family%20Dental!5e0!3m2!1sen!2sau!4v1732025462136!5m2!1sen!2sau" width="600" height="450" style={{"border": "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                            <div className="location-name">
                                <AiFillHome />
                                <p>274 Melbourne St, North Adelaide SA 5006</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="appointment-form-area">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Appointment;