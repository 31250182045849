import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
// import ServicesData from './ServiceData';
// import Service from '../../components/Service/Service';
// import { Link } from 'react-router-dom';
// import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services = () => {
    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="We keep the fees as fair as possible to make it affordable for everyone!" subTitle="About Us"/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>The cost of dental treatment varies greatly depending on the type of treatment.</p>
                        <p className='service-title-text'>At Smile Bright Family Dental, we accept all major health funds and aim to keep fees affordable.</p>
                        <p className='service-title-text'>We also bulk bill patients who qualify for the Medicare Child Dental Benefits Scheme.</p>
                        <p className='service-title-text'>We thoroughly explain all treatment options and fee estimates before commencing any treatment.</p>
                        <br></br>
                        <p className='service-title-text'>Payment is required on the day</p>
                        <p className='service-title-text'>Payments  accepted:</p>
                        <p className='service-title-text'> • Electronic Hicaps facilities</p>
                        <p className='service-title-text'> • EFTPOS/ Visa and Mastercard</p>
                        <p className='service-title-text'> • Afterpay</p>
                    </div>
                </div>

                {/* <div className="row">
                    {
                        ServicesData.map(singleService => <Service serviceList={singleService}/>)
                    }
                </div> */}
            </div>

            {/* <div className="services-link text-center">
                <Link to='/'>
                    View all service list
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div> */}
        </section>
    );
};

export default Services;